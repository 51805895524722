import React from "react";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import ContactForm from "../components/contactForm";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const ContactUsPage = ({ data }) => {
	const siteUrl = data.site.siteMetadata.siteUrl;
	const contactImg = data.contactImg.localFile.childImageSharp.gatsbyImageData;

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<GatsbySeo
				title="Get in touch with ARC"
				description="Get in touch today | 0203 393 3973"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact`,
					title: "Get in touch with ARC",
					description: "Get in touch today | 0203 393 3973",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/038Antoinette-Chappell-IMG_9481-scaled-1.jpg`,
							width: 2560,
							height: 1707,
							alt: "Contact ARC Writing and translation services",
						},
					],
				}}
			/>
			<section
				style={{
					background:
						"transparent linear-gradient(108deg, #FBCAEC7D 0%, #FF96DC7A 50%, #F2C8E5 100%) 0% 0% no-repeat padding-box",
					opacity: "1",
					zIndex: "-999",
				}}
			>
				<Navigation background="none" />
				<Container className="py-md-6 py-4 ">
					<Row>
						<Col className="d-none d-lg-block" lg={7} xl={6}>
							<div
								style={{ maxWidth: "30rem", height: "25rem" }}
								className="bg-background position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "30rem", height: "25rem" }}
										image={contactImg}
										alt={data.contactImg.altText}
									/>
								</div>
							</div>
						</Col>

						<Col lg={5}>
							<h1 className="fs-2">Get in touch today</h1>
							<hr
								className="bg-secondary"
								style={{ height: "3px", width: "150px", opacity: "1" }}
							/>
							<div className="shadow py-4 py-md-5 px-4 px-md-6 px-lg-4 px-xl-6">
								<ContactForm />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		contactImg: wpMediaItem(title: { eq: "Contact-Image" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default ContactUsPage;
