import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("mnqllyzb");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}
	return (
		<Form className="w-100 text-center" onSubmit={handleSubmit}>
			<Form.Group className="mb-4" controlId="name">
				<Form.Control
					style={{ border: "2px solid #707070" }}
					name="name"
					placeholder="Name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-4" controlId="email">
				<Form.Control
					style={{ border: "2px solid #707070" }}
					name="_replyto"
					type="email"
					placeholder="E-mail"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-4" controlId="telephone">
				<Form.Control
					style={{ border: "2px solid #707070" }}
					type="tel"
					name="telephone"
					placeholder="Phone"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-4  " controlId="message">
				<Form.Control
					style={{ border: "2px solid #707070" }}
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<div
				class="g-recaptcha mb-3"
				data-sitekey="6LftVVodAAAAALW0rwbDUXUXDwX-xwUvMLBqO7MA"
			></div>
			<Button
				variant="secondary"
				className=" cta-btn w-md-40 w-lg-60 w-xl-40 w-100 mx-auto py-2 "
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Message
			</Button>
		</Form>
	);
}
export default ContactForm;
